import { Container, Col, Row, Button } from "reactstrap";
import ContactForm from "../components/ContactForm";
import AnandNatyalayasLogo from '../app/assets/images/logo.jpg';
import DonateButton from "../components/DonateButton";


const ContactPage = () => {

    return (
    <Container>
        <Row className='row-content align-items-center'>
            <Col>
                <img  width = '40%' src= {AnandNatyalayasLogo} alt= 'logo'/>
            </Col>
            <Col sm='4'>
                <h3 className=" text-white">Our Address</h3>
                <address className="text-white">
                    5451 S Mingo Rd,
                    <br />
                    Building No. E
                    <br />
                    Tulsa, OK 74133
                    <br /><br />
                    Phone No: (918) 409-4344
                </address>
            </Col>
            <Col>
                <h3 className=" text-white">Support our Dance School</h3>
                <DonateButton />
            </Col>
        </Row>
        <Row className="row-content">
        <iframe 
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3224.372703971021!2d-95.87262501627997!3d36.0844017014495!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87b68d0f77d58493%3A0xdf638eda73f0d899!2s5451%20S%20Mingo%20Rd%2C%20Tulsa%2C%20OK%2074133!5e0!3m2!1sen!2sus!4v1686016427408!5m2!1sen!2sus" 
            width="600" 
            height="450" 
            style={{ border: "0"}} 
            title = 'Anand Natyalayas'
            allowfullscreen="" 
            loading="lazy" 
            referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
        </Row>
        <Row className='row-content'>
                <Col xs='12'>
                    <h2 className=" text-white">Send Us A Message</h2>
                    <p className="text-white">If you have questions about our classes, please send us a message.  We will get back to you as soon as possible.</p>
                    <hr/>
                </Col>
                <Col md='10'>
                    <ContactForm/>
                </Col>
            </Row>
    </Container>
    );
};

export default ContactPage;
import { Container } from "reactstrap";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import DanceImg1 from '../app/assets/images/danceimg1.jpg';
import DanceImg2 from '../app/assets/images/danceimg2.jpg';
import DanceImg3 from '../app/assets/images/danceimg3.jpg';
import DanceImg4 from '../app/assets/images/danceimg4.jpg';
import DanceImg5 from '../app/assets/images/danceimg5.jpg';
import DanceImg6 from '../app/assets/images/danceimg6.jpg';
import DanceImg7 from '../app/assets/images/danceimg7.jpg';
import DanceImg8 from '../app/assets/images/danceimg8.jpg';
import DanceImg9 from '../app/assets/images/danceimg9.jpg';
import DanceImg10 from '../app/assets/images/danceimg10.jpg';
import DanceImg11 from '../app/assets/images/danceimg11.jpg';
import DanceImg12 from '../app/assets/images/danceimg12.jpg';
import DanceImg13 from '../app/assets/images/danceimg13.jpg';
import DanceImg14 from '../app/assets/images/danceimg14.jpg';
import DanceImg15 from '../app/assets/images/danceimg15.jpg';
import DanceImg16 from '../app/assets/images/danceimg16.jpg';
import DanceImg17 from '../app/assets/images/danceimg17.jpg';
import DanceImg18 from '../app/assets/images/danceimg18.jpg';
import DanceImg19 from '../app/assets/images/danceimg19.jpg';
import Krishna1 from '../app/assets/images/krishna1.jpeg';
import Krishna2 from '../app/assets/images/krishna2.jpeg';
import Krishna3 from '../app/assets/images/krishna3.jpeg';
import Krishna4 from '../app/assets/images/krishna4.jpeg';
import Krishna5 from '../app/assets/images/krishna5.jpeg';
import Krishna6 from '../app/assets/images/krishna6.jpeg';
import Krishna7 from '../app/assets/images/krishna7.jpg';
import Krishna8 from '../app/assets/images/krishna8.jpg';
import Krishna9 from '../app/assets/images/krishna9.jpg';
import Krishna10 from '../app/assets/images/krishna10.jpg';
import Krishna11 from '../app/assets/images/krishna11.jpg';


const SummerShowPage = () => {

    const images = [
        {
          original: DanceImg11,
          thumbnail: DanceImg11,
          originalWidth:'400',
          originalHeight: '400',
        },
        {
          original: DanceImg2,
          thumbnail: DanceImg2,
          originalWidth:'400',
          originalHeight: '400',
        },
        {
          original: DanceImg3,
          thumbnail: DanceImg3,
          originalWidth:'400',
          originalHeight: '400',
        },
        {
            original: DanceImg4,
            thumbnail: DanceImg4,
            originalWidth:'400',
            originalHeight: '400',
        },
        {
            original: DanceImg5,
            thumbnail: DanceImg5,
            originalWidth:'400',
            originalHeight: '400',
        },
        {
            original: DanceImg6,
            thumbnail: DanceImg6,
            originalWidth:'400',
            originalHeight: '400',
        },
        {
            original: DanceImg7,
            thumbnail: DanceImg7,
            originalWidth:'400',
            originalHeight: '400',
        },
        {
            original: DanceImg8,
            thumbnail: DanceImg8,
            originalWidth:'400',
            originalHeight: '400',
        },
        {
            original: DanceImg9,
            thumbnail: DanceImg9,
            originalWidth:'400',
            originalHeight: '400',
        },
        {
            original: DanceImg10,
            thumbnail: DanceImg10,
            originalWidth:'400',
            originalHeight: '400',
        },
        {
            original: DanceImg1,
            thumbnail: DanceImg1,
            originalWidth:'400',
            originalHeight: '400',
        },
        {
            original: DanceImg12,
            thumbnail: DanceImg12,
            originalWidth:'400',
            originalHeight: '400',
        },
        {
            original: DanceImg13,
            thumbnail: DanceImg13,
            originalWidth:'400',
            originalHeight: '400',
        },
        {
            original: DanceImg14,
            thumbnail: DanceImg14,
            originalWidth:'400',
            originalHeight: '400',
        },
        {
            original: DanceImg15,
            thumbnail: DanceImg15,
            originalWidth:'400',
            originalHeight: '400',
        },
        {
            original: DanceImg16,
            thumbnail: DanceImg16,
            originalWidth:'400',
            originalHeight: '400',
        },
        {
            original: DanceImg17,
            thumbnail: DanceImg17,
            originalWidth:'400',
            originalHeight: '400',
        },
        {
            original: DanceImg18,
            thumbnail: DanceImg18,
            originalWidth:'400',
            originalHeight: '400',
        },
        {
            original: DanceImg19,
            thumbnail: DanceImg19,
            originalWidth:'400',
            originalHeight: '400',
        },
    ];
    const images2 = [
        {
            original: Krishna1,
            thumbnail: Krishna1,
            originalWidth:'400',
            originalHeight: '400',
        },
        {
            original: Krishna2,
            thumbnail: Krishna2,
            originalWidth:'400',
            originalHeight: '400',
        },
        {
            original: Krishna3,
            thumbnail: Krishna3,
            originalWidth:'400',
            originalHeight: '400',
        },
        {
            original: Krishna4,
            thumbnail: Krishna4,
            originalWidth:'400',
            originalHeight: '400',
        },
        {
            original: Krishna5,
            thumbnail: Krishna5,
            originalWidth:'400',
            originalHeight: '400',
        },
        {
            original: Krishna6,
            thumbnail: Krishna6,
            originalWidth:'400',
            originalHeight: '400',
        },
        {
            original: Krishna7,
            thumbnail: Krishna7,
            originalWidth:'400',
            originalHeight: '400',
        },
        {
            original: Krishna8,
            thumbnail: Krishna8,
            originalWidth:'400',
            originalHeight: '400',
        },
        {
            original: Krishna9,
            thumbnail: Krishna9,
            originalWidth:'400',
            originalHeight: '400',
        },
        {
            original: Krishna10,
            thumbnail: Krishna10,
            originalWidth:'400',
            originalHeight: '400',
        },
        {
            original: Krishna11,
            thumbnail: Krishna11,
            originalWidth:'400',
            originalHeight: '400',
        }
    ]
      

    return(
        <Container>
            <div className='row-content'>
                <h2 className='text-center text-white'>Baratha Pravaham 2015</h2>
                <ImageGallery items={images} />
            </div>
            <div className='row-content'>
                <h2 className='text-center text-white'>Baratha Pravaham 2017</h2>
            </div>
            <div className='row-content'>
                <h2 className='text-center text-white'>Baratha Pravaham 2019</h2>
                <p className='text-center text-white'>"Krishna, The Divine"</p>
                <ImageGallery items={images2} />
            </div>
        </Container>
    );
};

export default SummerShowPage;
import { useState } from "react";
import 'bootstrap/dist/css/bootstrap.css';  
import Carousel from 'react-bootstrap/Carousel'    
import Dance from '../../app/assets/images/Bright_Dance-202.jpg';
import Dance1 from '../../app/assets/images/Bright_Dance-181.jpg';
import Featured from '../../app/assets/images/Bright_Dance-183.jpg';

const CarouselHome = () => {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
    };

    return (
        <Carousel fade variant="dark" activeIndex={index} onSelect={handleSelect}>
            <Carousel.Item interval = {2000}>
                <img
                className="d-block w-100"
                src={Dance}
                alt="Bharatanatyam pose 1"
                />
            </Carousel.Item>
            <Carousel.Item interval = {2000}>
                <img
                className="d-block w-100"
                src={Dance1} 
                alt="Bharatanatyam pose 2"
                />
            </Carousel.Item>
            <Carousel.Item interval = {2000}>
                <img
                className="d-block w-100"
                src={Featured} 
                alt="Bharatanatyam pose 3"
                />
            </Carousel.Item>
        </Carousel>
    );
}

export default CarouselHome;
import { Button, Label, Col, FormGroup } from "reactstrap";
import { useDispatch } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { validateContactForm } from "../utils/validateContactForm";
import { postMessage, recaptchaAuth } from "./contactSlice";
import ReCAPTCHA from "react-google-recaptcha";
import { useRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";



const ContactForm = () => {
   
    const dispatch = useDispatch();
    const recaptchaRef = useRef(null);
    //const recaptchaRef = React.createRef();

    const notifySuccess = () => {
        toast("Message sent successfully!", {
            position: toast.POSITION.BOTTOM_CENTER,
            className: 'toast-message'
        });
    };
    const notifyError = () => {
        toast("Something went wrong!", {
            position: toast.POSITION.BOTTOM_CENTER,
            className: 'toast-message'
        });
    };
    toast.configure();

    const getRecaptchaScore = () => {
        const inputVal = ContactForm.email;
        const token = recaptchaRef.current.getValue();
        console.log("token = "+token);
        dispatch(recaptchaAuth({inputVal: inputVal, token: token}));
    };

    const handleSubmit = (values,{resetForm}) => {
        console.log('form values:', values);
        console.log('in JSON format:', JSON.stringify(values));

        const message = {
            firstName: values.firstName,
            lastName: values.lastName,
            phoneNum: values.phoneNum,
            email: values.email,
            message: values.message
        }
        dispatch(getRecaptchaScore);
        dispatch(postMessage(message));
        resetForm();
        recaptchaRef.current.reset();
        
    };

    

    return(
        <Formik
        initialValues={{
            firstName: '',
            lastName: '',
            phoneNum: '',
            email: '',
            message: ''
        }}
        onSubmit ={handleSubmit}
        validate ={validateContactForm}
        >
            <Form style={{ color: '#FFF',fontFamily: 'Philosopher'}}>
                <FormGroup row>
                    <Label htmlFor='firstName' md='2'>First Name</Label>
                    <Col md='10'>
                        <Field 
                        name ='firstName'
                        placeholder ='First Name'
                        className ='form-control'/>
                        <ErrorMessage name = 'firstName'>
                            {(msg) => <p className='text-white'>{msg}</p>}
                        </ErrorMessage>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label htmlFor= 'lastName' md='2'>Last Name</Label>
                    <Col md='10'>
                        <Field 
                        name ='lastName'
                        placeholder ='Last Name'
                        className ='form-control'/>
                        <ErrorMessage name = 'lastName'>
                            {(msg) => <p className='text-white'>{msg}</p>}
                        </ErrorMessage>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label htmlFor='phoneNum' md='2'>Phone</Label>
                    <Col md='10'>
                        <Field 
                        name ='phoneNum'
                        placeholder ='Phone'
                        className ='form-control'/>
                        <ErrorMessage name = 'phoneNum'>
                            {(msg) => <p className='text-white'>{msg}</p>}
                        </ErrorMessage>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label htmlFor='email' md='2'>Email</Label>
                    <Col md='10'>
                        <Field 
                        name ='email'
                        placeholder='Email'
                        type ='email'
                        className ='form-control'/>
                        <ErrorMessage name = 'email'>
                            {(msg) => <p className='text-white'>{msg}</p>}
                        </ErrorMessage>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label htmlFor='message' md='2'>Message</Label>
                    <Col md='10'>
                        <Field 
                            name ='message'
                            as= 'textarea'
                            rows='6'
                            className ='form-control'
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md ={{size:10, offset:2}}>
                        <ReCAPTCHA 
                            sitekey={process.env.REACT_APP_SITE_KEY} 
                            ref={recaptchaRef}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md ={{size:10, offset:2}}>
                        <Button onClick={Object.values(validateContactForm).length === 0 ? notifySuccess : notifyError} type= 'submit' color='danger'>
                            Send Message
                        </Button>
                    </Col>
                </FormGroup>
            </Form>
        </Formik>
    );
};

export default ContactForm;
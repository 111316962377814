import { useState, useEffect } from 'react';
import { Col, Row, Container } from "reactstrap";
import { useSpring, animated } from 'react-spring';
import Featured from '../app/assets/images/Bright_Dance-183.jpg';

const AboutPage = () => {
    const [toggle, setToggle] = useState(false);

    const animatedStyle = useSpring({
        opacity: toggle ? 1 : 0,
        transform: toggle ? 'scale(1,1)' : 'scale(1,0)',
        config: { duration: 500 }
    });

    useEffect(() => {
        setToggle(true);
    }, []);

    return (
        <Container>
            <Row>
                <h2 className='text-white'>About Us</h2>
            </Row>
            <Row className="row-content">
                <Col sm= '6'>
                    <animated.div style={animatedStyle}>
                    <img width = '100%' src= {Featured} alt= 'featured' />
                    </animated.div>
               </Col> 
               <Col sm = '6'>
                    <p className="text-white">
                        Dharshini established the Anand Natyalayas in 2007. The students of Anand Natyalayas perform at different venues in Tulsa. Their annual recital is very popular with unique choreography and interpretation that brings to life the stories of Indian mythology. This connects the students to the rich cultural heritage of India. Dharshini’s passion and dedication to dance is very evident with her attention to detail and infinite patience with her students as they learn very intricate dance movements. Her vision is to give the gift of love for Bharatanatyam to her students and the community. <br/><br/>
                        Dharshini has been able to achieve her goals with the support of her husband Anand and her three active children.
                    </p>
               </Col>
            </Row>
        </Container>
    );
};

export default AboutPage;
import { Container, Row} from "reactstrap";
import YoutubeEmbed from "../features/display/YoutubeEmbed";

const GalleryPage = () => {
    return(
        <Container>
            <h2 className=" text-white">Videos</h2>
            <Row className="row-content">
                <YoutubeEmbed embedId="ghsiaEDk1xA" />
            </Row>
            <Row className="row-content">
                <YoutubeEmbed embedId="QQbm3tUur6k" />
            </Row>
            <Row className="row-content">
                <YoutubeEmbed embedId="CMSJ6kgFEUU" />
            </Row>
            <Row className="row-content">
                <YoutubeEmbed embedId="ABDWPn6JDBM" />
            </Row>
        </Container>
    )
};

export default GalleryPage;
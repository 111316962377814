import { useState } from "react";
import { Container, Row, Col, Modal, Card, CardBody } from "reactstrap";
import Promotion1 from '../app/assets/images/promotion.jfif';
import Bollywood from '../app/assets/images/bollywood.jpg';
import StarsRating from 'stars-rating';
import CarouselHome from "../features/display/CarouselHome";

const HomePage = () => {
    const [modal1, setModal1] = useState(false);
    const [modal2, setModal2] = useState(false);
    const [image1, setImage1] = useState('Promotion1');
    const [image2, setImage2] = useState('Bollywood');

    const toggle1 = () => {
        setModal1(!modal1);
        setImage1(!image1);
    };
    const toggle2 = () => {
        setModal2(!modal2)
        setImage2(!image2);
    };


    return (
    <Container>
        <Row className="row-content">
            <h1 className="text-center text-white">
                Welcome to Anand Natyalayas!
            </h1>
            <p className="text-center text-white">
                One of the most distinguished Dance Schools in Tulsa, Oklahoma.
            </p>
            <p className="text-center text-white">
                We specialize in Bharatanatyam Dance, Classical Indian Dance and Indian Bollywood Dance.
            </p>
        </Row>
        <Row className="row-content">
        <CarouselHome/>
        </Row>
        <Row className="row-content text-white">
            <h2>Promotions</h2>
            <Col className='col-md-2'></Col>
            <Col>
                <img  onClick={toggle1} width = '50%' src ={Promotion1} alt= 'Dance School Poster'/>
                <Modal isOpen={modal1} toggle={toggle1}>
                    <img width = '100%' src ={Promotion1} alt= ''/>   
                </Modal>
            </Col>
            <Col>
                <img  onClick={toggle2} width = '50%' src ={Bollywood} alt= 'bollywood dance poster'/>
                <Modal isOpen={modal2} toggle={toggle2}>
                    <img width = '100%' src ={Bollywood} alt= ''/>
                </Modal>
            </Col>
        </Row>
        <Row className="row-content text-white">
            <h2>Reviews</h2>
            <Card className="mt-3">
                <CardBody>
                    <StarsRating className="displayed" count={5} value={5} size={26} color={'#ffd700'} edit={false}/>
                    <blockquote className="blockquote text-center">
                        <p className="mb-0">True form of Classical Dance and most beautiful part is GURU herself</p>
                        <br/>
                        <footer className="blockquote-footer">Sweety Patel</footer>
                    </blockquote>
                </CardBody>
                <CardBody>
                <StarsRating className="displayed" count={5} value={5} size={26} color={'#ffd700'} edit={false}/>
                    <blockquote className="blockquote text-center">
                        <p className="mb-0">Dharshini Madam is so talented and professional. Glad my daughter is student in Anand Natyalayas.</p>
                        <br/>
                        <footer className="blockquote-footer">Lekha patra</footer>
                    </blockquote>
                </CardBody>
                <CardBody>
                <StarsRating className="displayed" count={5} value={5} size={26} color={'#ffd700'} edit={false}/>
                    <blockquote className="blockquote text-center">
                        <p className="mb-0">Discipline, dedication and honesty</p>
                        <br/>
                        <footer className="blockquote-footer">Lakshmi</footer>
                    </blockquote>
                </CardBody>
            </Card>
        </Row>
    </Container>
    );
};

export default HomePage;
import PropTypes from "prop-types";

const YoutubeEmbed = ({ embedId }) => (
    <div className="video-responsive">
        <iframe 
            width="853" 
            height="480" 
            src={`https://www.youtube.com/embed/${embedId}`}
            title="Anand Natyalayas: Bharatanatyam - Muralidhara Kavuthuwam" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowFullScreen
        />
        <iframe 
            width="853" 
            height="480" 
            src={`https://www.youtube.com/embed/${embedId}`} 
            title="Anand Natyalayas: Bharatanatyam - Ardhanareeswaram" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowFullScreen
        />
         <iframe 
            width="853" 
            height="480" 
            src={`https://www.youtube.com/embed/${embedId}`} 
            title="Anand Natyalayas: Bharatanatyam - Ardhanareeswaram" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowFullScreen
        />
        <iframe 
            width="853" 
            height="480" 
            src={`https://www.youtube.com/embed/${embedId}`} 
            title="Anand Natyalayas: Bharatanatyam - Ardhanareeswaram" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowFullScreen
        />
    </div>
  );
  
  YoutubeEmbed.propTypes = {
    embedId: PropTypes.string.isRequired
  };
  
  export default YoutubeEmbed;
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import { Routes, Route } from 'react-router-dom';
import ContactPage from './pages/ContactPage';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import MeetTheGuruPage from './pages/MeetTheGuruPage';
import SummerShowPage from './pages/SummerShowPage';
import ArangetramPage from './pages/ArangetramPage';
import GalleryPage from './pages/GalleryPage';

function App() {
  return (
    <div className="App">
      <Header/>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='meettheguru' element={<MeetTheGuruPage/>} />
        <Route path='about' element={<AboutPage />} />
        <Route path='contact' element={<ContactPage />} />
        <Route path='summershow' element={<SummerShowPage />} />
        <Route path='arangetram' element={<ArangetramPage />} />
        <Route path='gallery' element={<GalleryPage />} />
         </Routes>
      <Footer/>
    </div>
  );
}

export default App;

import React, { useEffect, useMemo ,useRef} from 'react';
import { useState } from 'react';

let counter = 0;

const generateId = () => {
    return `ID-${counter++}`; // if it is necessary, use some better unique id generator
};

const DonateButton = () => {
    const visible = useRef(false);

    const buttonRef = useRef(null);
    const buttonId = useMemo(() => `ID-${generateId()}`, []);
    useEffect(() => {
        console.log("entering visible ? ", visible);
        if(!visible.current) {
        visible.current = true;
        const button = window.PayPal.Donation.Button({
            env: 'production',
            business :process.env.REACT_APP_PAYPAL_BUSINESS_ID,
            image: {
               src: 'https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif',
               alt: 'Donate with PayPal button',
               title: 'PayPal - The safer, easier way to pay online!',
            }
        });
        button.render(`#${buttonRef.current.id}`);
        
        console.log("exiting visible ? ", visible);
    }
        return () => {
            console.log(`dontate button removed`)
          } // you can change the code and run it when DOM is ready
    }, [visible]);
    return (
        <div ref={buttonRef} id={buttonId} />
    );
};

export default DonateButton;
import { Container, Card, CardBody, CardTitle, CardText } from "reactstrap";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import MainPhoto from '../app/assets/images/Main_Photo.jpg';
import Photo1 from '../app/assets/images/3A.jpg';
import Photo2 from '../app/assets/images/8A.jpg';
import Photo3 from '../app/assets/images/5danceA.jpg';
import Photo4 from '../app/assets/images/Bright_Dance-102.jpg';
import Photo5 from '../app/assets/images/Bright_Dance-112.jpg';
import MainPose from '../app/assets/images/Bright_Dance-189.jpg';
import Pose1 from '../app/assets/images/Bright_Dance-187.jpg';
import Pose2 from '../app/assets/images/Bright_Dance-190.jpg';
import Pose3 from '../app/assets/images/Bright_Dance-191.jpg';
import Pose4 from '../app/assets/images/Bright_Dance-193.jpg';
import Pose5 from '../app/assets/images/Bright_Dance-194.jpg';
import BrochureMain from '../app/assets/images/Brochure_Main.jpg';
import Brochure1 from '../app/assets/images/Brochure_1.jpg';
import Brochure2 from '../app/assets/images/Brochure_2.jpg';
import Brochure3 from '../app/assets/images/Brochure_3.jpg';
import Brochure4 from '../app/assets/images/Brochure_4.jpg';
import Brochure5 from '../app/assets/images/Brochure_5.jpg';
import NishaMainPose from '../app/assets/images/Nisha-Main-Pose.jpg';
import NishaPose1 from '../app/assets/images/Nisha-Pose-1.jpg';
import NishaPose2 from '../app/assets/images/Nisha-Pose-2.jpg';
import NishaPose3 from '../app/assets/images/Nisha-Pose-3.jpg';
import NishaPose4 from '../app/assets/images/Nisha-Pose-4.jpg';
import NishaPose5 from '../app/assets/images/Nisha-Pose-5.jpg';
import ReeyaMainPose from '../app/assets/images/Reeya_Brochure _Main.jpg';
import ReeyaPose1 from '../app/assets/images/Reeya Brochure Pose1.jpg';
import ReeyaPose2 from '../app/assets/images/Reeya Brochure Pose2.jpg';
import ReeyaPose3 from '../app/assets/images/Reeya Brochure Pose3.jpg';
import ReeyaPose4 from '../app/assets/images/Reeya Brochure Pose4.jpg';
import ReeyaPose5 from '../app/assets/images/Reeya Brochure Pose5.jpg';
import RishaMainPose from '../app/assets/images/Risha Brochure Main.jpg';
import RishaPose1 from '../app/assets/images/Risha Brochure Pic 1.jpg';
import RishaPose2 from '../app/assets/images/Risha Brochure Pic 2.jpg';
import RishaPose3 from '../app/assets/images/Risha Brochure Pic 3.jpg';
import RishaPose4 from '../app/assets/images/Risha Brochure Pic 4.jpg';
import RishaPose5 from '../app/assets/images/Risha Brochure Pic 5.jpg';
import BrindaPose1 from '../app/assets/images/0331brinda 2-1.jpg';
import BrindaPose2 from '../app/assets/images/0449 brinda 4.jpg';
import BrindaPose3 from '../app/assets/images/0550 brinda 9.jpg';
import BrindaPose4 from '../app/assets/images/0682brinda 11.jpg';
import BrindaPose6 from '../app/assets/images/0785brinda 16.jpg';
import BrindaPose7 from '../app/assets/images/0801brinda 18.jpg';
import RiyaPose1 from '../app/assets/images/00718.jpg';
import RiyaPose2 from '../app/assets/images/00784.jpg';

const ArangetramPage = () => {

    const HannahImg = [
        {
            original: MainPhoto,
            thumbnail: MainPhoto,
            originalWidth:'400',
            originalHeight: '400',
            originalAlt: 'Main photo',
        },
        {
            original: Photo1,
            thumbnail: Photo1,
            originalWidth:'400',
            originalHeight: '400',
            originalAlt: 'Dance photo 1',
        },
        {
            original: Photo2,
            thumbnail: Photo2,
            originalWidth:'400',
            originalHeight: '400',
            originalAlt: 'Dance photo 2',
        },
        {
            original: Photo3,
            thumbnail: Photo3,
            originalWidth:'400',
            originalHeight: '400',
            originalAlt: 'Dance photo 3',
        },
        {
            original: Photo4,
            thumbnail: Photo4,
            originalWidth:'400',
            originalHeight: '400',
            originalAlt: 'Dance photo 4',
        },
        {
            original: Photo5,
            thumbnail: Photo5,
            originalWidth:'400',
            originalHeight: '400',
            originalAlt: 'Dance photo 5',
        },
    ]
    const KirtiImg = [
        {
            original: MainPose,
            thumbnail: MainPose,
            originalWidth:'400',
            originalHeight: '400',
            originalAlt: 'Main pose',
        },
        {
            original: Pose1,
            thumbnail: Pose1,
            originalWidth:'400',
            originalHeight: '400',
            originalAlt: 'Dance pose 1',
        },
        {
            original: Pose2,
            thumbnail: Pose2,
            originalWidth:'400',
            originalHeight: '400',
            originalAlt: 'Dance pose 2',
        },
        {
            original: Pose3,
            thumbnail: Pose3,
            originalWidth:'400',
            originalHeight: '400',
            originalAlt: 'Dance pose 3',
        },
        {
            original: Pose4,
            thumbnail: Pose4,
            originalWidth:'400',
            originalHeight: '400',
            originalAlt: 'Dance pose 4',
        },
        {
            original: Pose5,
            thumbnail: Pose5,
            originalWidth:'400',
            originalHeight: '400',
            originalAlt: 'Dance pose 5',
        },
    ]
    const MeghanaImg = [
        {
            original: BrochureMain,
            thumbnail: BrochureMain,
            originalWidth:'400',
            originalHeight: '400',
            originalAlt: 'Brochure Main photo',
        },
        {
            original: Brochure1,
            thumbnail: Brochure1,
            originalWidth:'400',
            originalHeight: '400',
            originalAlt: 'Brochure photo 1',
        },
        {
            original: Brochure2,
            thumbnail: Brochure2,
            originalWidth:'400',
            originalHeight: '400',
            originalAlt: 'Brochure photo 2',
        },
        {
            original: Brochure3,
            thumbnail: Brochure3,
            originalWidth:'400',
            originalHeight: '400',
            originalAlt: 'Brochure photo 3',
        },
        {
            original: Brochure4,
            thumbnail: Brochure4,
            originalWidth:'400',
            originalHeight: '400',
            originalAlt: 'Brochure photo 4',
        },
        {
            original: Brochure5,
            thumbnail: Brochure5,
            originalWidth:'400',
            originalHeight: '400',
            originalAlt: 'Brochure photo 5',
        },
    ]
    const NishaImg = [
        {
            original: NishaMainPose,
            thumbnail: NishaMainPose,
            originalWidth:'400',
            originalHeight: '400',
            originalAlt: 'Nisha Main pose',
        },
        {
            original: NishaPose1,
            thumbnail: NishaPose1,
            originalWidth:'400',
            originalHeight: '400',
            originalAlt: 'Nisha pose 1',
        },
        {
            original: NishaPose2,
            thumbnail: NishaPose2,
            originalWidth:'400',
            originalHeight: '400',
            originalAlt: 'Nisha pose 2',
        },
        {
            original: NishaPose3,
            thumbnail: NishaPose3,
            originalWidth:'400',
            originalHeight: '400',
            originalAlt: 'Nisha pose 3',
        },
        {
            original: NishaPose4,
            thumbnail: NishaPose4,
            originalWidth:'400',
            originalHeight: '400',
            originalAlt: 'Nisha pose 4',
        },
        {
            original: NishaPose5,
            thumbnail: NishaPose5,
            originalWidth:'400',
            originalHeight: '400',
            originalAlt: 'Nisha pose 5',
        },
    ]
    const ReeyaImg = [
        {
            original: ReeyaMainPose,
            thumbnail: ReeyaMainPose,
            originalWidth:'400',
            originalHeight: '400',
            originalAlt: 'Reeya main pose',
        },
        {
            original: ReeyaPose1,
            thumbnail: ReeyaPose1,
            originalWidth:'400',
            originalHeight: '400',
            originalAlt: 'Reeya pose 1',
        },
        {
            original: ReeyaPose2,
            thumbnail: ReeyaPose2,
            originalWidth:'400',
            originalHeight: '400',
            originalAlt: 'Reeya pose 2',
        },
        {
            original: ReeyaPose3,
            thumbnail: ReeyaPose3,
            originalWidth:'400',
            originalHeight: '400',
            originalAlt: 'Reeya pose 3',
        },
        {
            original: ReeyaPose4,
            thumbnail: ReeyaPose4,
            originalWidth:'400',
            originalHeight: '400',
            originalAlt: 'Reeya pose 4',
        },
        {
            original: ReeyaPose5,
            thumbnail: ReeyaPose5,
            originalWidth:'400',
            originalHeight: '400',
            originalAlt: 'Reeya pose 5',
        },
    ]
    const RishaImg = [
        {
            original: RishaPose4,
            thumbnail: RishaPose4,
            originalWidth:'400',
            originalHeight: '400',
            originalAlt: 'Risha pose 4',
        },
        {
            original: RishaPose1,
            thumbnail: RishaPose1,
            originalWidth:'400',
            originalHeight: '400',
            originalAlt: 'Risha pose 1',
        },
        {
            original: RishaPose2,
            thumbnail: RishaPose2,
            originalWidth:'400',
            originalHeight: '400',
            originalAlt: 'Risha pose 2',
        },
        {
            original: RishaPose3,
            thumbnail: RishaPose3,
            originalWidth:'400',
            originalHeight: '400',
            originalAlt: 'Risha pose 3',
        },
        {
            original: RishaMainPose,
            thumbnail: RishaMainPose,
            originalWidth:'400',
            originalHeight: '400',
            originalAlt: 'Risha main pose',
        },
        {
            original: RishaPose5,
            thumbnail: RishaPose5,
            originalWidth:'400',
            originalHeight: '400',
            originalAlt: 'Risha pose 5',
        },
    ]
    const BrindaImg = [
        {
            original: BrindaPose1,
            thumbnail: BrindaPose1,
            originalWidth:'400',
            originalHeight: '400',
            originalAlt: 'Brinda pose 1',
        },
        {
            original: BrindaPose2,
            thumbnail: BrindaPose2,
            originalWidth:'400',
            originalHeight: '400',
            originalAlt: 'Brinda pose 2',
        },
        {
            original: BrindaPose3,
            thumbnail: BrindaPose3,
            originalWidth:'400',
            originalHeight: '400',
            originalAlt: 'Brinda pose 3',
        },
        {
            original: BrindaPose4,
            thumbnail: BrindaPose4,
            originalWidth:'400',
            originalHeight: '400',
            originalAlt: 'Brinda pose 4',
        },
        {
            original: BrindaPose6,
            thumbnail: BrindaPose6,
            originalWidth:'400',
            originalHeight: '400',
            originalAlt: 'Brinda pose 6',
        },
        {
            original: BrindaPose7,
            thumbnail: BrindaPose7,
            originalWidth:'400',
            originalHeight: '400',
            originalAlt: 'Brinda pose 7',
        }
    ]
    const RiyaImg = [
        {
            original: RiyaPose1,
            thumbnail: RiyaPose1,
            originalWidth:'400',
            originalHeight: '400',
            originalAlt: 'Riya pose 1',
        },
        {
            original: RiyaPose2,
            thumbnail: RiyaPose2,
            originalWidth:'400',
            originalHeight: '400',
            originalAlt: 'Riya pose 2',
        }
    ]
 
    return(
        <Container>
            <div className='row-content'>
                <h2 className='text-center text-white'>Arangetram 2022</h2>
                <Card>
                    <ImageGallery items={HannahImg} />
                    <CardBody>
                        <CardTitle className="text-center" style={{fontWeight: 'bolder'}}>
                            HANNAH VARICKAPPALLIL
                        </CardTitle>
                        <CardText>
                            Hannah Varickappallil is the daughter of Sheeba and Joe Varickappallil. She has two older brothers, Stefan and Harris. She will be attending the University of Tulsa this fall. Hannah has been learning the art of Bharatanatyam since she was five years lod under the guidance of Smt. Dharshini Mala Anandmoorthy. Hannah is treasurer for a nationally recognized medical club, HOSA. Bharatanatyam has allowed Hannah to learn about her Indian culture. Hannah has gained a life of discipline, devotion and passion. She plans to carry these teachings for the rest of her life. She is thankful to God for all the opportunities and blessings showered upon her. Hannah is forever in debt to Mala Aunty for being her guru and teaching her the authentic art form of Bharatanatyam.
                        </CardText>
                    </CardBody>
                </Card>
            </div>
            <div className='row-content'>
                <Card>
                    <ImageGallery items={KirtiImg} />
                    <CardBody>
                        <CardTitle className="text-center" style={{fontWeight: 'bolder'}}>
                            KIRTI GOWDA
                        </CardTitle>
                        <CardText>
                            Kirti Gowda has been learning Bharatanatyam since she was 5 years old from Guru Smt. Dharshini Mala Anandmoorthy. Her passion and love for dance have only grown over the past 12 years. Bharatanatyam has not only brought her closer to her closest friends, but also brought her closer to her culture and faith. Along with dance, Kirti loves being involved at her School. She is a rising senior at Jenks High School and participates in Mock Trial, and Academic Team. She was very involved in the Jenks Basketball Program and Tulsa Soccer Club and is involved in the International Scholars Program, AP Capstone Program, Student Council, National Honor Society, Equality Alliance and Drug Free Youth. She is the Cofounder/Co-President of the Young Voters League and has/will be the girls representative class officer for the class of 2023 for 4 years. Kirti has the amazing opportunity to organize and host the only student-led Tedx in the region next year. In her free time, she likes to rewatch and binge The Vampire Diaries, spend quality time with her family, including her dog Brodie and hang out with her friends.
                        </CardText>
                    </CardBody>
                </Card>
            </div>
            <div className='row-content'>
                <Card>
                    <ImageGallery items={MeghanaImg} />
                    <CardBody>
                        <CardTitle className="text-center" style={{fontWeight: 'bolder'}}>
                            MEGHANA VENKATESHA
                        </CardTitle>
                        <CardText>
                            Meghana Venkatesha began learning Bharatanatyam at the young age of 4 under the tutelage of Guru Smt. Dharshini Mala Anandmoorthy. This incredible journey has not only enhanced her Indian classical dance skills but also instilled discipline, mental strength and a passion for dance. Additionally, Bharatanatyam has allowed Meghana to stay connected to her heritage and religion. She has performed at the Hindu Temple and many other events around Tulsa. Meghana graduated as a valedictorian from Union High School. She is a National Merit finalist, distinguished graduate and one of the 100 Oklahoma Academic All-Staters. She was president of several clubs including National Honor Society, Tulsa Union Medical Society, U-Bake and UHS Today. Meghana was very involved on the Union Speech and Debate team and competed nationally numerous times. This fall, Meghana will be attending the University of Southern California. Outside of School, Meghana is a member of Youth Philanthropy Initiative's Cohort 13. She was co-facilitator of the Youth Advisory Board at Youth Services of Tulsa. She loves to paint, scrapbook and engage in photography, while also spending quality time with her parents and older sister Monica.
                        </CardText>
                    </CardBody>
                </Card>
            </div>
            <div className='row-content'>
                <Card>
                    <ImageGallery items={NishaImg} />
                    <CardBody>
                        <CardTitle className="text-center" style={{fontWeight: 'bolder'}}>
                            NISHA BASAVARAJU
                        </CardTitle>
                        <CardText>
                            Nisha started Bharatanatyam at the age of 5 under the tutelage of Smt. Priya Raju, Hema Kumar and Darshini Mala Anandmoorthy. Connecting with her Indian culture, Nisha has been deeply rooted with dance since a young age. She has perfomed at various dance events in Tulsa and India, as wellas teaching orphanage kids Indian dances at Dillon International Camp over the summer. This coming August, Nisha will be a senior at Booker T. Washington High School, currently enrolled in full IB(International Baccalaureate). She is also a member of the Teen Advisory Board (TAB), STUCO and Spanish and Medical Anatomy Club. Additionally, Nisha is the Vice President of the National Honors Society board. She has also continued to volunteer at the Food Bank and Little Light House (helping children with special needs) from her freshman year. Apart from school, in her free time, Nisha enjoys riding her bike in her neighborhood, hanging out with her friends and family and spending as much time as she can with her dog, Cooper. Nisha has always loved growing up dancing and being able to spend quality time with her felow dancers.
                        </CardText>
                    </CardBody>
                </Card>
            </div>
            <div className='row-content'>
                <Card>
                    <ImageGallery items={ReeyaImg} />
                    <CardBody>
                        <CardTitle className="text-center" style={{fontWeight: 'bolder'}}>
                            REEYA RAMASAMY
                        </CardTitle>
                        <CardText>
                            Reeya Ramasamy was first introduced to dance as a 4 year old, where she started Bharatanatyam as the disciple of Smt. Dharshini Mala. As the years have passed, Reeya has enjoyed how Bharatanatyam connected her to her roots while allowing for her to stay active and axpress herself. To further her connection to her roots, Reeya has studied and taught through the Tulsa Tamil Academy. Reeya has graduated from Union High Schol, where she balanced a hefty load of extracurriculars along with dance. She played tennis for the Union Tennis Team, played the viola in the Union Symphony Orchestra, served as the President of both UHS Key Club and Sustainable U and served as a representative on the Union Superintendent Student Advisory Council. Outside of school, she served as a co-facilitator for the Youth Philanthropy Initiative and the Chair of Tulsa Regional STEM Alliance's Teen Advisory Council. Reeya graduated with many honors, including but not limited to: valedictorian, National Merit finalist, Heisman Sports Scholar and Miss Union. Reeya will be attending Purdue University this fall where she will be studying Cybersecurity and hopes to continue her journey with Bharatanatyam.
                        </CardText>
                    </CardBody>
                </Card>
            </div>
            <div className='row-content'>
                <Card>
                    <ImageGallery items={RishaImg} />
                    <CardBody>
                        <CardTitle className="text-center" style={{fontWeight: 'bolder'}}>
                            RISHA PATEL
                        </CardTitle>
                        <CardText>
                            Risha Patel is a rising junior at Austin High School in Houston, Texas. Despite moving 500 miles away from her hometown, Tulsa in 2017, she retained her strong bond with Bharatanatyam and continued dancing under her guru, Smt. Dharshini Mala. Risha started her dance journey at the tenser age of 6 but has been passionate about dance ever since she learned how to crawl. She believes dance to be a getaway from reality and a medium to cope with the fluctuations of life, always keeping dance an indispensable part of her journey. Aside from dance, she is an active and successful member in her school's Speech and Debate program, where she is a national qualifier and captain of the speech team. She also plays the cello in her school's Orchestra. In her free time, she enjoys reading psychological thrillers and romance novels as well as spending time laughing with her friendsand family, eating out and watchingdramas. To make a living, she aspires to enter either the education or thepsychology field upon graduation.
                        </CardText>
                    </CardBody>
                </Card>
            </div>
            <div className='row-content'>
                <Card>
                    <CardBody>
                        <CardTitle className="text-center" style={{fontWeight: 'bolder'}}>
                            BRINDHA CHELLA
                        </CardTitle>
                    </CardBody>
                    <ImageGallery items={BrindaImg} />
                </Card>
            </div>
            <div className='row-content'>
                <Card>
                    <CardBody>
                        <CardTitle className="text-center" style={{fontWeight: 'bolder'}}>
                            RIYA KAUL
                        </CardTitle>
                    </CardBody>
                    <ImageGallery items={RiyaImg} />
                </Card>
            </div>
        </Container>
    )
};

export default ArangetramPage;